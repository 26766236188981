<template>
  <div class="activities_box_all" :style="{background:dataList.backColor}">
    <div class="activities_box">
      <img :src="dataList.backGround.url" alt="">
    </div>

    <h1>
      实力黑马
    </h1>

    <div class="digital_activity_list">
      <div class="group_commend_left">
        <div class="box_all">
          <div v-for="(item,index) in list[0].goodsInfo" :key="index" class="box_list" @click="btnListGo(item)">
            <div class="box_list_img">
              <img :src="item.goodCover" alt="">
            </div>
            <div class="box_list_span">{{ item.goodName }}</div>
            <div class="box_list_type">
              <span class="box_list_vip_price">¥{{ item.vipPrice }}</span>
              <span class="box_list_price">¥{{ item.goodPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1>
      TOP5
    </h1>
    <div class="digital_list">
      <div v-for="(item,index) in list[1].goodsInfo" :key="index" @click="btnListGo(item)">
        <img :src="item.goodCover" alt="">
      </div>
    </div>
    <h1>
      热卖榜
    </h1>

    <div class="digital_activity_list">
      <div class="group_commend_left">
        <div class="box_all">
          <div v-for="(item,index) in list[2].goodsInfo" :key="index" class="box_list" @click="btnListGo(item)">
            <div class="box_list_img">
              <img :src="item.goodCover" alt="">
            </div>
            <div class="box_list_span">{{ item.goodName }}</div>
            <div class="box_list_type">
              <span class="box_list_vip_price">¥{{ item.vipPrice }}</span>
              <span class="box_list_price">¥{{ item.goodPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import { getActivityCategoryInfo } from '@/services/api'
import { newActivityList } from '@/services/activity'

export default {
  name: 'DigitalActivities',
  components: {
  },
  data() {
    return {
      dataList: '',
      list: ''
    }
  },
  mounted() {
    this.gitActivityCategoryInfo()
    this.gitActivityList()
  },
  methods: {
    gitActivityCategoryInfo: function() {
      const data = {
        id: this.$route.query.id
      }
      getActivityCategoryInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          this.dataList = res.data.activityInfo
        }
      })
    },
    // list
    gitActivityList: function() {
      const data = {
        id: this.$route.query.id
      }
      newActivityList(data).then((res) => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    //
    btnListGo: function(item) {
      const obj = {
        'product_type': 1, // 暂时写1  自营
        'goodId': item.goodId,
        'skuId': item.skuId
      }
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>
<style scoped lang="less">

.activities_box_all {
  .activities_box {
    h1 {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .digital_activity_list {
    overflow: hidden;

    .group_commend_title {
      //padding-top: 12px;
      box-sizing: border-box;
    }

    .group_commend_left {
      overflow: hidden;
      height: 190px;
      //margin-top: 12px;
    }

    .box_all {
      padding: 10px 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow-y: auto;
    }

    .box_list {
      width: 120px;
      margin-left: 8px;
      background: #fff;
      display: inline-block;
      border-radius: 4px;
      padding: 4px;
      box-sizing: border-box;
    }

    .box_list_img {
      width: 110px;
      height: 110px;

      img {
        width: 100%;
      }
    }

    .box_list_span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;
      margin-top: 8px;
    }

    .box_list_type {
      text-align: left;
      margin-top: 12px;

      .box_list_vip_price {
        color: red;
        font-size: 14px;
        margin-top: 2px;
      }

      .box_list_price {
        color: #999;
        text-decoration: line-through;
        font-size: 10px;
      }
    }
  }

  .digital_list {
    margin: 12px;

    div {
      margin-top: 8px;
    }
  }
}

</style>
